import { h, Fragment } from 'preact';
import { Link, getCurrentUrl } from 'preact-router';
import { connect } from 'redux-zero/react';
import classNames from 'classnames';
import faHome from '@fortawesome/fontawesome-pro/svgs/light/home.svg';
import faImages from '@fortawesome/fontawesome-pro/svgs/light/images.svg';
import faEnvelope from '@fortawesome/fontawesome-pro/svgs/light/envelope.svg';
import faClipboardList from '@fortawesome/fontawesome-pro/svgs/light/clipboard-list.svg';
import faLockAlt from '@fortawesome/fontawesome-pro/svgs/light/lock-alt.svg';
import faDownload from '@fortawesome/fontawesome-pro/svgs/light/download.svg';

import { openModal } from '../../contactUsModal/actions';
import { sendLog } from '../../../helpers/apis/logger';
import { openInstallPrompt, canInstall } from '../../../helpers/installPrompt';

import LanguageButton from './LanguageButton';

import Intl, { translate } from '../../common/intl';
import FaIcon from '../../common/faIcon';

import style from '../style.scss';

const InstallBtn = ({ closeFn }) => {
  if (!canInstall()) {
    return null;
  }

  const installClickHandler = () => {
    openInstallPrompt();
    closeFn();
    sendLog('info', { type: 'pwa', event: 'install btn click' });
  };

  return (
    <button
      type="button"
      className={style.navbarItem}
      onClick={() => installClickHandler()}
    >
      <span className={style.menuItemIcon}>
        <FaIcon icon={faDownload} />
      </span>
      <span className={style.menuItemText}>
        <Intl textKey="I18N_MENU_INSTALL">Add to Home Screen</Intl>
      </span>
    </button>
  );
};

const Menu = (props) => {
  const {
    isActive,
    slug,
    userId,
    closeFn,
    openContactUsModal,
    srs,
    isUserBanned = false,
    hideRetakeBtn,
  } = props;

  const logLinkHandler = (link) => {
    sendLog('info', {}, getCurrentUrl(), link, 'triggered');
    closeFn();
  };

  const privacyLink = translate('I18N_TERMS_MODAL_PRIVACY_POLICY_LINK', 'https://amplificam.com/privacy/');
  const termsLink = translate('I18N_TERMS_MODAL_TOS_LINK', 'https://amplificam.com/terms-of-use/');

  const contactUsHandler = () => {
    sendLog('info', {}, window.location.pathname, 'contactUsModal', 'triggered');
    const SRS = `${srs.section || '_'}-${srs.row || '_'}-${srs.seat || '_'}`;
    openContactUsModal(`Question, event: ${slug}, ticket# ${userId}${isUserBanned ? '!' : ''} ${SRS}`);
    closeFn();
  };
  return (
    <div className={classNames(style.menu, { [style.menuActive]: isActive })}>
      <div className={style.navbarStart}>
        {!hideRetakeBtn && (
          <Link className={classNames(style.navbarItem, { [style.active]: getCurrentUrl() === '/' })} href="/">
            <span className={style.menuItemIcon}>
              <FaIcon icon={faHome} />
            </span>
            <span className={style.menuItemText}>
              <Intl textKey="I18N_MENU_HOME">Home</Intl>
            </span>
          </Link>
        )}
        <Link
          className={classNames(style.navbarItem, {
            [style.active]: getCurrentUrl() === '/album',
          })}
          href="/album"
        >
          <span className={style.menuItemIcon}>
            <FaIcon icon={faImages} />
          </span>
          <span className={style.menuItemText}>
            <Intl textKey="I18N_MENU_ALBUM">Photos</Intl>
          </span>
        </Link>
        <LanguageButton closeFn={closeFn} />
        <button
          type="button"
          className={style.navbarItem}
          onClick={contactUsHandler}
        >
          <span className={style.menuItemIcon}>
            <FaIcon icon={faEnvelope} />
          </span>
          <span className={style.menuItemText}>
            <Intl textKey="I18N_MENU_CONTACT_US">Contact Us</Intl>
          </span>
        </button>
        {process.env.SKIP_TERMS_AND_CONDITIONS !== true && (
          <Fragment>
            <a
              href={termsLink}
              target="_blank"
              rel="noopener noreferrer"
              className={style.navbarItem}
              onClick={() => logLinkHandler(termsLink)}
            >
              <span className={style.menuItemIcon}>
                <FaIcon icon={faClipboardList} />
              </span>
              <span className={style.menuItemText}>
                <Intl textKey="I18N_MENU_TERMS">
                  Terms and Conditions
                </Intl>
              </span>
            </a>
            <a
              href={privacyLink}
              target="_blank"
              rel="noopener noreferrer"
              className={style.navbarItem}
              onClick={() => logLinkHandler(privacyLink)}
            >
              <span className={style.menuItemIcon}>
                <FaIcon icon={faLockAlt} />
              </span>
              <span className={style.menuItemText}>
                <Intl textKey="I18N_MENU_PRIVACY">
                  Privacy Policy
                </Intl>
              </span>
            </a>
          </Fragment>
        )}
        <InstallBtn closeFn={closeFn} />
      </div>
    </div>
  );
};

export default connect(
  ({
    event: { slug },
    userId = {},
    srs,
    isUserBanned,
    hideRetakeBtn,
  }) => ({
    slug,
    userId,
    srs,
    isUserBanned,
    hideRetakeBtn,
  }),
  () => ({ openContactUsModal: openModal }),
)(Menu);
